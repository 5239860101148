import React from "react"
import { graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import parse from "html-react-parser"
// import Latest from "../pages/latest"

// We're using Gutenberg so we need the block styles
// these are copied into this project due to a conflict in the postCSS
// version used by the Gatsby and @wordpress packages that causes build
// failures.
// @todo update this once @wordpress upgrades their postcss version
import "../css/@wordpress/block-library/build-style/style.css"
import "../css/@wordpress/block-library/build-style/theme.css"

// import Bio from "../components/bio"
import Layout from "../components/layout"
import Seo from "../components/seo"
import IssoComments from "../pages/issocomments"

const BlogPostTemplate = ({ data: { previous, next, post } }) => {
  const featuredImage = {
    data: post.featuredImage?.node?.localFile?.childImageSharp?.gatsbyImageData,
    alt: post.featuredImage?.node?.alt || ``,
  }
  const img_src = featuredImage?.data?.images?.fallback?.src;

  return (
    <Layout>

      <Seo title={post.title} description={post.excerpt} image={img_src} />

      <article
        className="blog-post"
        itemScope
        itemType="http://schema.org/Article"
      >
        {/* <pre className="m-1">{JSON.stringify(featuredImage.data.images, null, 4)}</pre> */}
        <header>
          <h1 itemProp="headline" className="mb-2 text-2xl font-semibold text-black dark:text-slate-300">{parse(post.title)}</h1>
          <p className="mb-3 font-normal text-blue-500 dark:text-gray-400 text-xs">
              <div className="flex opacity-75 text-sm">
                <span>
                  {new Date(post.date).getDate()}&nbsp; 
                  {new Date(post.date).toLocaleString('default', { month: 'long' })}&nbsp; 
                  {new Date(post.date).getFullYear()} 
                </span>
                &nbsp;
                <span className="pr-2"> {post.categories.nodes[0].name}</span>
                {/* <span>
                  <svg class="h-5 w-5 text-blue-500"  width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">  <path stroke="none" d="M0 0h24v24H0z"/>  <path d="M4 21v-13a3 3 0 0 1 3 -3h10a3 3 0 0 1 3 3v6a3 3 0 0 1 -3 3h-9l-4 4" />  <line x1="8" y1="9" x2="16" y2="9" />  <line x1="8" y1="13" x2="14" y2="13" /></svg>
                </span>
                <span className="pr-2">100</span>

                <span>
                  <svg class="h-5 w-5 text-blue-500"  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  stroke-width="2"  stroke-linecap="round"  stroke-linejoin="round">  <path d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z" />  <circle cx="12" cy="12" r="3" /></svg>
                </span>
                <span>200</span> */}
              </div>
              <div className="flex opacity-75 text-sm">
                {post.author.node.name}
              </div>
          </p>
          {/* <pre className="m-1">{JSON.stringify(post, null, 4)}</pre> */}
      
          {/* if we have a featured image for this post let's display it */}
          {featuredImage?.data && (
            <GatsbyImage
              image={featuredImage.data}
              alt={featuredImage.alt}
              style={{ marginBottom: 20 }}
            />
          )}
        </header>

        {!!post.content && (
          <section className="article-content leading-7 dark:text-slate-300" itemProp="articleBody">{parse(post.content)}</section>
        )}

        <hr />

        {/* <footer>
          <Bio />
        </footer> */}
      </article>

      {/* <nav className="blog-post-nav">
        <ul
          style={{
            display: `flex`,
            flexWrap: `wrap`,
            justifyContent: `space-between`,
            listStyle: `none`,
            padding: 0,
          }}
        >
          <li>
            {previous && (
              <Link to={previous.uri} rel="prev">
                ← {parse(previous.title)}
              </Link>
            )}
          </li>

          <li>
            {next && (
              <Link to={next.uri} rel="next">
                {parse(next.title)} →
              </Link>
            )}
          </li>
        </ul>
      </nav> */}
      <IssoComments />
    </Layout>
  )
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query BlogPostById(
    $id: String!
    $previousPostId: String
    $nextPostId: String
  ) {
    post: wpPost(id: { eq: $id }) {
      id
      excerpt
      content
      title
      author{
        node {
          id
          name
          nicename
          firstName
          lastName
        }
      }
      categories{
        nodes{
          name
        }
      }
      date(formatString: "DD MMMM, YYYY")
      featuredImage {
        node {
          altText
          localFile {
            childImageSharp {
              gatsbyImageData(
                quality: 100
                placeholder: TRACED_SVG
                layout: FULL_WIDTH
              )
            }
          }
        }
      }
    }
    previous: wpPost(id: { eq: $previousPostId }) {
      uri
      title
    }
    next: wpPost(id: { eq: $nextPostId }) {
      uri
      title
    }
  }
`
